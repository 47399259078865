import React, { useState } from "react";
/** @jsx jsx */
import { Box, jsx, useThemeUI } from "theme-ui";
import {
  Container,
  Flex,
  FlexGrid,
  Heading,
  Text,
  AspectRatio,
  Button,
} from "../../components/core";
import Page from "../../components/Page";
import Contact from "../../components/Contact";
import { Parallax } from "../../components/animate";
import { alpha } from "@theme-ui/color";
import { motion } from "framer-motion";
import { graphql } from "gatsby";
import { BackgroundVague, Vague } from "../../components/svg";
import SEO from "../../components/SEO";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Card } from "../../components/Card";

const Partenaires = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter;
  const { theme } = useThemeUI();
  const [currentTab, setCurrentTab] = useState(0);
  const isTabActive = (activeTab) => activeTab === currentTab;

  return (
    <Page>
      <SEO
        title="Nos partenaires"
        description="Retrouvez l'ensemble des activités de Clean my Calanques !"
      />
      <AspectRatio ratio={[1440 / 630, 1440 / 480, 1440 / 315]}>
        <Parallax
          alt="Image en-tête évenements"
          image={getImage(frontmatter.image)}
          backgroundColor={alpha("primary", 0.25)(theme)}
        >
          <Flex
            sx={{
              width: "100%",
              height: "100%",
              py: 0,
              px: [2, null, 0],
            }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.6, delay: 0.8 }}
              sx={{ textAlign: "center" }}
            >
              <Heading as="h1" color="white">
                Nos partenaires
              </Heading>
            </motion.div>
          </Flex>
        </Parallax>
      </AspectRatio>
      <Box
        sx={{
          backgroundColor: "background3",
        }}
      >
        <Container sx={{ paddingTop: [8, null, 10] }}>
          <Box>
            <Heading as="h2" color="secondary" sx={{ marginBottom: 3 }}>
              {frontmatter.intro.title}
            </Heading>
            <Text>{frontmatter.intro.text}</Text>
            <Vague
              color="primary"
              sx={{
                marginTop: 6,
              }}
            />
          </Box>
        </Container>

        <BackgroundVague
          width="100%"
          height={null}
          sx={{
            transform: "scaleX(-1) rotate(180deg)",
            bottom: "-1px",
          }}
        />
      </Box>

      {/*<BackgroundVague width="100%" height={null} color="background3" />*/}

      <Container
        sx={{
          paddingTop: [4, 7, 8],
        }}
      >
        <Box
          as="section"
          sx={{
            paddingBottom: [8, null, 10],
            backgroundColor: "white",
          }}
        >
          <Flex
            gap={2}
            wrap="wrap"
            positions={["flex-start", "center"]}
            sx={{ marginBottom: 8 }}
          >
            {frontmatter.sections.map(({ section }, i) => (
              <Button
                key={i}
                variant="badge"
                active={isTabActive(i)}
                outline
                onClick={() => {
                  setCurrentTab(i);
                }}
              >
                {section.name}
              </Button>
            ))}
          </Flex>
          {frontmatter.sections[currentTab].section.partenaires && (
            <FlexGrid
              positions={["center", "stretch"]}
              columns={
                frontmatter.sections[currentTab].section.affichage ===
                "Images moyennes"
                  ? [4, 4, 3]
                  : [3, 3, 2]
              }
              gutterX={
                frontmatter.sections[currentTab].section.affichage ===
                "Images moyennes"
                  ? [8, 14, 20]
                  : [8, 8, 10]
              }
              gutterY={8}
            >
              {frontmatter.sections[currentTab].section.partenaires.map(
                ({ partenaire }, index) => (
                  <Box key={index}>
                    <Flex sx={{ height: "100%" }}>
                      <GatsbyImage
                        image={getImage(partenaire.image)}
                        alt={`Image partenaire`}
                        style={{
                          width: "100%",
                          cursor:
                            partenaire.link !== "" ? "pointer" : "initial",
                        }}
                        loading={index < 6 ? "eager" : "lazy"}
                        onClick={() => {
                          partenaire.link !== "" &&
                            window.open(partenaire.link, "_blank");
                        }}
                      />
                    </Flex>
                  </Box>
                )
              )}
            </FlexGrid>
          )}
        </Box>
      </Container>

      <Contact colorVague="white" />
    </Page>
  );
};

export default Partenaires;

export const query = graphql`
  query PartenairesQueryData {
    markdownRemark(fields: { slug: { eq: "/partenaires/" } }) {
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        intro {
          title
          text
        }
        sections {
          section {
            name
            affichage
            partenaires {
              partenaire {
                link
                image {
                  childImageSharp {
                    gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
